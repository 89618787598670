import React from "react";
import useFetch from "../hooks/useFetch";
import { strapiURL, wpURL } from "../components/URL";
import ReactMarkdown from "react-markdown";
import { Parallax, Background } from "react-parallax";
import "../css/Home.css";
import "../css/Blog.css";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import { motion } from "framer-motion";

const Blog = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 1000;
  React.useEffect(() => {
    window.scrollTo(0, 0);
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  //const { loading, error, data } = useFetch(strapiURL + "/api/blogs?populate=*");
  const { loading, error, data } = useFetch(wpURL + "blogs?per_page=100");
  if (loading) return <Loading />;
  if (error) return <p>Error :I</p>;

  /*let datablogs = [...data.data].reverse();

    datablogs.sort((a,b)=>{
        return new Date(a.attributes.publishedAt) - new Date(b.attributes.publishedAt);
    })
    datablogs.reverse();*/
  let datablogs = data;
  datablogs.sort((a, b) => {
    return new Date(a.date) - new Date(b.date);
  });
  datablogs.reverse();
  function getPreview(content, chars) {
    //return content.substring(content.indexOf("<p>") + 3, content.indexOf("</p>")).substring(0, chars);
    return content.substring(0, chars) + "...";
  }

  return (
    <div style={{ backgroundColor: "black" }}>
      <motion.div
        className="blogs"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        {width > 1000 &&
          datablogs.map((blog) => (
            <div>
              <Parallax
                className="imageBox"
                bgImage={blog.acf.titleimage}
                strength={250}
                //blur={{ min: -15, max: 12 }}
              >
                <div
                  className="leftBox"
                  style={{
                    height: "100vh",
                    backgroundImage:
                      "linear-gradient(180deg,rgba(0,0,0,1),rgba(0,0,0, 0),rgba(0,0,0, 1))",
                  }}
                >
                  <div className="textBox">
                    <h2>{blog.title.rendered}</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: getPreview(blog.content.rendered, 400),
                      }}
                    ></div>
                    <Link className="toBlogButton" to={`/blog/${blog.id}`}>
                      Mehr erfahren
                    </Link>
                  </div>
                </div>
              </Parallax>
              <div className="divider"></div>
            </div>
          ))}
        {width <= 1000 &&
          datablogs.map((blog) => (
            <div className="blogsMobile">
              <div className="mobileBlog">
                <div
                  className="blogIMG"
                  style={{
                    backgroundImage: `linear-gradient(180deg,
                        rgba(0,0,0, 0) 40%,
                        rgba(0,0,0, 1)),url("${blog.acf.titleimage}")`,
                  }}
                ></div>
                <div className="blogText">
                  <h2>{blog.title.rendered}</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getPreview(blog.content.rendered, 160),
                    }}
                  ></div>
                  <Link className="toBlogButton" to={`/blog/${blog.id}`}>
                    Mehr erfahren
                  </Link>
                </div>
              </div>
            </div>
          ))}
      </motion.div>
    </div>
  );
};

export default Blog;

{
  /**style={{backgroundImage: `linear-gradient(180deg,rgba(0,0,0, 0) 50%,
                rgba(0,0,0, 1)),url(${blog.attributes.titleImage.data.attributes.url})`}} */
}
